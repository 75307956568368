<!--  登录   -->
<template>
  <div class="container">
    <div class="logo">
      <div>
        <img :src="thumb" alt="">
        <!-- <img src="https://passport.zhulong.com/img/mlogin/logo.png" alt=""> -->
      </div>
      <!-- <div>
        <img src="../../static/images/my_index/logo_text.png" alt="" class="logo_text">

      </div> -->
    </div>
    <div class="tab_login">
      <div v-if="is_num" :class="tabs ? 'is_tab' : ''" class="tab_nav" @click="account_login(2)">
        账号登录
      </div>
      <div v-if="is_Captcha" :class="tabs1 ? 'is_tab' : ''" class="tab_nav" @click="account_login(1)">
        验证码登录
      </div>
    </div>
    <div v-if="numer_login" class="numer_login">
      <div class="phone">
        <input v-model="num" type="text" placeholder="用户名/手机号/邮箱" @change="isshows()">
      </div>
      <div class="password">
        <div v-show="is_pass">
          <input v-model="password" type="password" placeholder="请输入密码" @change="isshows()">
        </div>
        <div v-show="is_code">
          <input v-model="password" type="text" placeholder="请输入密码">
        </div>
      </div>
    </div>
    <div v-if="code_login" class="code_login">
      <div class="phone">
        <div class="diqu">
          <input v-model="guoji" type="text">
          <!-- <img src="../../assets/down_t.png" alt="" /> -->
        </div>
        <input v-model="phone" style="background: #fff !important" class="phone_inp" type="text" placeholder="请输入手机号">
      </div>
      <div class="code_box">
        <div class="code_m">
          <input v-model="ucode" type="text" placeholder="请输入验证码">
        </div>
        <div v-show="iscode" class="get_code" @click="getcode()">
          {{ codeBtnText }}
        </div>
        <div v-show="againcode" class="get_code">
          {{ code_text }}
        </div>
      </div>
    </div>
    <div v-show="isShowBtn" :disabled="isdisabled" class="login_btn" @click="sign_in(1)">
      登录
    </div>
    <div v-show="!isShowBtn" :disabled="isdisabled" class="login_btn_yzm" @click="sign_in(2)">
      登录/注册
    </div>
    <div class="forget_pass"><span @click="forgetPassword">忘记密码</span></div>
    <!-- <div v-show="isShowBtn" class="register" @click="register()">注册新账号</div> -->
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import { JSEncrypt } from 'jsencrypt'
import { loginByUserName, loginByPhone, sendMobileCode, getSnsUserInfo, InsetBind } from '@/api/login.js'
import { getagencyInfo } from '@/api/hometop.js'
import { setWeChatBind } from '@/api/wxbind.js'
import { Toast } from 'vant'
import WechatState from '@/utils/isWechatState.js'

export default {
  data() {
    return {
      isShowBtn: true,
      tabs: true,
      codeBtnText: '获取验证码',
      codeCount: 59,
      tabs1: false,
      againcode: '',
      is_code: '',
      password: '',
      active: true,
      is_pass: true,
      phone: '',
      guoji: '+86',
      numer_login: true,
      code_login: false,
      type: 2,
      num: '',
      ucode: '',
      iscode: true,
      thumb: '',
      is_num: true,
      is_Captcha: true,
      is_qiye: true,
      code_text: '111',
      isdisabled: false,
      randstr: '',
      ticket: '',
      timer: null,
      popup_id: 0,
      redirect: '',
      // 微信公众号
      authGzhId: 'wxf3117cecab674509',
      auth_code: '', // 微信授权code
      openid: '', // 通过后台交换code获取openid
      unionid: '', // 通过后台交换code获取unionid
      headimgurl: '', // 通过后台交换code获取 headimgurl
      nickname: '', // 通过后台交换code获取 nickname
      sex: '' // 通过后台交换code获取 sex
    }
  },
  created() {
    this.isshows()
    this.getParams()

    // 检测环境授权
    if (this.checkWechatH5()) {
      // 微信浏览器下
      if (this.$route.query.code) {
        this.auth_code = this.$route.query.code
        // 检测是否绑定或绑定等
        this.getOpenIdFunc()
      } else {
        if (!(this.$route.query.openid && this.$route.query.unionid && this.$route.query.nickname)) {
          this.showAuthBind()
        }
      }
    }

    this.popup_id = this.$route.query.popup_id
    this.getagencyInfoFun()
    // 加载滑块js
    this.loadTCaptchaJS()
  },
  mounted() {

  },
  methods: {
    // 加载滑块验证码
    loadTCaptchaJS() {
      if (!WechatState.isWechatMiniprogram && !document.getElementById('loadTCaptchaJS')) {
        // 创建script标签，引入外部文件
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = './TCaptcha.js'
        script.id = 'loadTCaptchaJS'
        document.getElementsByTagName('head')[0].appendChild(script)
      }
    },
    // 判断是微信h5环境
    checkWechatH5() {
      const isWxH5 = WechatState.isWechat && !WechatState.isWechatMiniprogram && !WechatState.wxwork
      return isWxH5
    },
    // 微信js授权绑定
    showAuthBind() {
      // 回调之后回到之前的页面
      var redirect = this.redirect
      redirect = encodeURIComponent(redirect)
      const origin = window.location.origin
      var auth_redirect = `${origin}/ucenter/old_login?redirect=${redirect}`// 微信授权回调页面
      console.log(auth_redirect, 'auth_redirect')
      auth_redirect = encodeURIComponent(auth_redirect)
      const appid = this.authGzhId
      const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${auth_redirect}&response_type=code&scope=snsapi_userinfo&state=success#wechat_redirect`
      window.location.href = url
    },
    // 根据授权code获取unionid和openid
    getOpenIdFunc() {
      this.aloading = true
      getSnsUserInfo({ code: this.auth_code }).then(res => {
        if (res.errNo == 0) {
          const result = res.result || {}
          if (result.openid) {
            this.openid = result.openid || ''
            this.unionid = result.unionid || ''
            this.nickname = result.nickname || ''
            this.headimgurl = result.headimgurl || ''
            this.sex = result.sex || 0
          } else {
            this.$message.error('授权失败')
          }
        } else {
          this.$message.error('授权失败')
        }
        this.aloading = false
      }).catch(() => {
        this.aloading = false
      })
    },
    // 新的绑定 : 绑定接口
    callForBindWechat(ares) {
      const openid = this.openid
      const unionid = this.unionid
      const nickname = this.nickname
      const headimgurl = this.headimgurl
      const query = {
        openid: openid,
        unionid: unionid,
        nickname: nickname,
        headimgurl: headimgurl
      }
      InsetBind(query).then(res => {
        console.log(res, 'resres')
      }).then(() => {
        // 登录成功
        this.loginSuccessActions(ares)
      })
    },
    getParams() {
      const query_redirect = this.$route.query.redirect
      if (query_redirect) {
        console.log(query_redirect, 'query_redirect')
        var redirect = ''
        try {
          redirect = decodeURIComponent(query_redirect)
        } catch (error) {
          console.log(error)
          redirect = window.location.origin
        }
        this.redirect = redirect
      } else {
        this.redirect = window.location.origin
      }
      console.log(this.redirect, 'this.redirect')
    },
    getagencyInfoFun() {
      getagencyInfo().then(res => {
        const origin = window.location.origin
        if (Cookies.get('agency_id') == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
          this.thumb = 'https://newoss.zhulong.com/forum/202403/14/35/144935jl86yt3x5dopva6l.png'
        } else {
          this.thumb = res.result.thumb
        }
        this.short_name = res.result.short_name
        console.log(res)
      })
    },
    getcode() {
      if (this.timer) return
      var reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/
      if (this.guoji == '86' || this.guoji == '+86') {
        if (!reg.test(this.phone)) {
          Toast('手机号格式有误')
          return false
        }
      } else {
        if (!this.phone || this.phone.length <= 0) {
          Toast('请输入手机号')
          return false
        }
      }

      if (WechatState.isWechatMiniprogram) {
        // 直接发验证码
        this.sendYzmNoCheck()
      } else {
        // 滑块验证
        this.useTencentCheck()
      }
    },
    // 验证
    useTencentCheck() {
      console.log('滑块验证')
      const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      const captcha = new TencentCaptcha(appid, function(res) {
        // eslint-disable-line
        // 用户滑动结束或者关闭弹窗，腾讯返回的内容
        console.log(res)
        if (res.ret == 0) {
          console.log(111)
          // 成功，传递数据给后台进行验证
          that.randstr = res.randstr
          that.ticket = res.ticket
          that.sendYzm()
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    },
    down_() {
      this.timer = setInterval(this.timerAction, 1000)
    },
    // 倒计时方法
    timerAction() {
      let time = this.codeCount
      if (time > 0) {
        const seconds = Math.floor(time % 60)
        const title = seconds + 's'
        time -= 1
        this.codeCount = time
        this.codeBtnText = title
      } else {
        this.closeTimer()
        this.codeCount = 59
        this.codeBtnText = '重新获取'
      }
    },
    // 关闭倒计时
    closeTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    sendYzm() {
      var params = {
        app_id: 1,
        mobile: this.phone,
        ticket: this.ticket,
        randstr: this.randstr,
        code_type: 1
      }
      sendMobileCode(params).then(res => {
        if (res.errNo === 0) {
          this.codeCount = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    // 不验证滑块的 直接发验证码
    sendYzmNoCheck() {
      const phone = this.phone
      var params = {
        type: 1,
        mobile: phone,
        code_type: 1,
        client_id: 500
      }
      sendMobileCode(params).then((res) => {
        if (res.errNo === 0) {
          this.codeCount = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    isshows() {
      if (this.password.length !== 0 && this.num.length !== 0) {
        this.isdisabled = true
      }
    },
    encrypt(msg) {
      var encrypt = new JSEncrypt()
      encrypt.setPublicKey(
        '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCrWVnSOu9m7O8X3taQGXzVlB9B0Gw1Mvbc0MxKZOxT8SlQVB1Krpu3KfuoxgGE1TikX/JkYJf+D4tTqENqk5pnSZc784gWZPEs2O+uz5R/8Ay8qP06uHDzw1oGDrpo8wxWQ7Ae2IwE2gTDtpcyg8NUJp14uYwsvA47iDpXHGmPxQIDAQAB-----END PUBLIC KEY-----'
      )
      return encrypt.encrypt(msg)
    },
    // 账号登陆与   &&  验证码登录切换/user/logon/signIn
    account_login(type) {
      this.type = type
      if (type === 1) {
        this.tabs1 = true
        this.tabs = false
        this.code_login = true
        this.numer_login = false
        this.isShowBtn = false
      } else {
        this.tabs = true
        this.tabs1 = false
        this.numer_login = true
        this.code_login = false
        this.isShowBtn = true
      }
      // this.active = !this.active
    },

    // 登录
    sign_in(type) {
      // console.log(this.num)
      // 账号密码登录
      if (type === 1) {
        if (!this.num) {
          Toast('请输入手机号')
          return
        }
        if (!this.password) {
          Toast('请输入密码')
          return
        }
        var params = {
          loginname: this.num,
          password: this.password,
          from_url: 'wap',
          type: 2,
          agency_id: Cookies.get('agency_id') || 2
        }
        loginByUserName(params).then(res => {
          // 检测是授权过的
          if (this.openid && this.unionid && this.nickname) {
            // 去绑定
            this.callForBindWechat(res)
          } else {
            // 登录成功
            this.loginSuccessActions(res)
          }
        })
      } else {
        if (!this.phone) {
          Toast('请输入手机号')
          return
        }
        if (!this.ucode) {
          Toast('请输入验证码')
          return
        }
        params = {
          mobile: this.phone,
          code: this.ucode,
          agency_id: Cookies.get('agency_id') || 2,
          type: 1,
          from_url: 'wap'
        }
        loginByPhone(params).then(res => {
          // 检测是授权过的
          if (this.openid && this.unionid && this.nickname) {
            this.callForBindWechat(res)
          } else {
            // 登录成功
            this.loginSuccessActions(res)
          }
        })
      }
    },
    // 登录接口调成功之后的处理，上面写在一起太恶心了，搞个方法处理，但是内容不变
    loginSuccessActions(res) {
      console.log(res)
      const errNo = res.errNo
      // 0、优先检测是否需要绑定手机号
      if (errNo == 160) {
        Toast('需要绑定手机号')
        this.$router.push({
          path: '/set_up/addPhone',
          query: {
            uuid: res.result.uuid,
            access_token: res.result.access_token
          }
        })
        return false
      }
      if (errNo == 0) {
        const result = res.result ? res.result : {}
        // 1、popup_id 处理
        if (this.popup_id) {
          window.location.href = `https://m.zhulong.com/interview/#/hrresume?id=${this.popup_id}`
          return false
        }

        // // 2、reg 处理
        // // const type = result.type ? result.type : ''
        // // 流程不通，暂时关闭
        // const type = ''
        // if (type === 'reg') {
        //   // 增加新用户注册
        //   this.newRegistInfo()
        //   return false
        // }

        // 3、unionID 处理
        if (this.$route.query.unionID) {
          this.setWeChatBind(res.result.uid)
        }

        // 4、关注公众号  处理
        if (this.canShowAttGzh(result)) {
          // 关注公众号
          this.showAttention()
          return
        }

        // 5、回到首页或redirect页面
        window.location.href = this.redirect
      } else {
        Toast(res.msg)
      }
    },
    // 是否出关注公众号，是否加判断是从拼团过来的 待定
    canShowAttGzh(val) {
      const is_bind_gongzh = val.is_bind_gongzh || ''
      const uid = val.uid || ''
      const isWxH5 = WechatState.isWechat && !WechatState.isWechatMiniprogram
      // 微信h5、普通h5，即：非小程序、非app
      if ((isWxH5 && is_bind_gongzh != 1) || uid == 8588314) {
        return true
      }
      return false
    },
    // 关注公众号
    showAttention() {
      // att_gzh
      const redirect = this.redirect
      this.$router.push({
        path: 'att_gzh',
        query: {
          redirect: redirect
        }
      })
    },

    // 根据unionID和uid进行微信绑定
    setWeChatBind(uid) {
      const info = {
        unionid: this.$route.query.unionID,
        uid: uid
      }
      setWeChatBind(info).then(res => {
        console.log(res)
      })
    },
    // 新注册信息页面
    newRegistInfo() {
      const redirect = this.redirect
      this.$router.push({ path: '/regInfo', query: { redirect: redirect }})
    },
    // 跳转到注册页面
    register() {
      this.$router.push({ path: '/login/register' })
    },
    forgetPassword() {
      // this.$router.push({ path: '/changePassword', query: { forget: 1 } })
      this.$router.push({ path: '/forgetPassword' })
    }
  }
}
</script>

<style lang='scss' scoped>
* {
  box-sizing: content-box;
}
.container {
  width: 100%;
  overflow: hidden;
  .logo {
    width: 100%;
    // margin: 0 auto;
    margin-top: 190px;
    text-align: center;
    img {
      width: 220px;
    }
    .logo_text {
      margin-top: -90px;
      width: 220px;
    }
  }
  .tab_login {
    width: 92%;
    margin: 0 4%;
    height: 65px;
    display: flex;
    margin-top: 40px;
    line-height: 45px;
    font-size: 36px;
    justify-content: space-around;
    border-bottom: 1px solid #dcdcdc;
    .is_tab {
      border-bottom: 4px solid #ee2e2e;
    }
  }
  .numer_login {
    width: 92%;
    margin: 0 4%;
    margin-top: 55px;
    height: 177px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    .phone {
      height: 88px;
      font-size: 32px;
      line-height: 88px;
      border-bottom: solid 1px #dcdcdc;
      width: 100%;
      input {
        width: 80%;
        margin: 0 5%;
      }
    }
    .password {
      height: 88px;
      font-size: 32px;
      line-height: 88px;
      width: 100%;
      input {
        width: 80%;
        margin: 0 5%;
      }
        .eyes {
        width: 30px;
        line-height: 88px;
        vertical-align: middle;
      }
    }
  }
  .code_login {
    width: 92%;
    margin: 0 4%;
    margin-top: 55px;
    height: 177px;
    background-color: #ffffff;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    .phone {
      height: 88px;
      font-size: 32px;
      line-height: 88px;
      border-bottom: solid 1px #dcdcdc;
      width: 100%;
      .diqu {
        width: 150px;
        float: left;
        border-right: 3px solid #dcdcdc;
        overflow: hidden;
        margin-top: 25px;
        height: 40px;
        input {
          float: left;
          width: 50%;
          font-size: 30px;
          margin-left: 20px;
          height: 40px;
          line-height: 4%;
        }
        img {
          float: left;
          width: 26px;
          margin-top: 15px;
        }
      }
      .phone_inp {
        width: 50%;
        height: 50px;
        line-height: 50px;
        margin-top: 20px;
        margin-left: 5%;
      }
    }
    .code_box {
      .code_m {
        width: 70%;
        float: left;
        height: 70px;
        margin-top: 10px;
        border-right: 2px solid #dcdcdc;
        input {
          line-height: 70px;
          margin-left: 20px;
          font-size: 32px;
        }
      }
      .get_code {
        float: left;
        color: #ee2e2e;
        font-size: 32px;
        width: 28%;
        text-align: center;
        line-height: 88px;
      }
    }
  }
  .login_btn {
    height: 88px;
    width: 92%;
    margin: 0 4%;
    background: #ee2e2e;
    border-radius: 10px;
    line-height: 90px;
    font-size: 32px;
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }
  .login_btn_yzm {
    height: 88px;
    width: 92%;
    margin: 0 4%;
    background: #ee2e2e;
    border-radius: 10px;
    line-height: 90px;
    font-size: 32px;
    color: #fff;
    margin-top: 30px;
    text-align: center;
  }
  .register {
    width: 92%;
    margin: 0 4%;
    line-height: 40px;
    margin-top: 40px;
    text-align: center;
    color: #999999;
    font-size: 28px;
  }
}
input:-internal-autofill-selected {
  background: #fff !important;
}
.forget_pass {
  text-align: center;
  color: #ee2e2e;
  line-height: 40px;
  padding-top: 30px;
}
</style>
